import { Avatar, Grid, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useGetFileMetas, useGetFileThumbnail } from "wcz-file";
import App from "../../models/App";

interface AppListItemProps {
    app: App;
}

export const AppListItem: React.FC<AppListItemProps> = ({ app }) => {
    const navigate = useNavigate();

    const { data: metas = [] } = useGetFileMetas(app.id);

    const { data: src } = useGetFileThumbnail(metas[0], {
        enabled: !!metas.length
    });

    const handleClick = useCallback(() => navigate(`/apps/${app.id}`), [app.id, navigate]);

    return (
        <ListItemButton onClick={handleClick}>
            <ListItemAvatar>
                <Avatar src={src} sx={{ width: 60, height: 60, borderRadius: 3 }} />
            </ListItemAvatar>
            <ListItemText
                primary={app.title}
                secondary={
                    <Grid container spacing={1}>
                        {app.categories.map(category =>
                            <Grid item key={category.id}><Typography variant="caption">{`#${category.name}`}</Typography></Grid>
                        )}
                    </Grid>
                }
                sx={{ ml: 2, mb: 1.3 }}
            />
        </ListItemButton>
    );
};