import { Button } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { Dialog, LayoutContext } from "wcz-layout";
import App from "../../models/App";
import { useGetApp, useUpdateApp } from "../../queries/AppQueries";
import { AppForm } from "./form/AppForm";

interface EditAppDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    id: string;
}

export const EditAppDialog: React.FC<EditAppDialogProps> = ({ open, setOpen, id }) => {
    const { t } = useContext(LayoutContext);
    const [app, setApp] = useState<App>({} as App);

    useGetApp(id, {
        enabled: open,
        refetchOnWindowFocus: false,
        onSuccess: data => setApp(data)
    });

    const { mutate } = useUpdateApp({
        onSuccess: () => onClose()
    });

    const handleOnSubmit = useCallback(() => {
        mutate(app);
    }, [app]);

    const onClose = useCallback(() => setOpen(false), []);

    return (
        <Dialog open={open} onClose={onClose} title={t("UpdateApplication")} color="primary" disableAutoClose actions={[
            <Button key="submit" variant="contained" onClick={handleOnSubmit}>{t("Submit")}</Button>
        ]}>
            <AppForm app={app} setApp={setApp} />
        </Dialog>
    );
};