import { ArrowUpward } from "@mui/icons-material";
import { Box, Container, Grid } from "@mui/material";
import React, { Fragment, useContext, useEffect } from "react";
import { LayoutContext, TypographyWithIcon, newGuid } from "wcz-layout";
import { ReleaseCard } from "../components/release/ReleaseCard";
import { useSearchRelease } from "../queries/ReleaseQueries";

const TAKE = 10;

export const ReleasesPage: React.FC = () => {
    const { changeTitle, t, i18n } = useContext(LayoutContext);

    useEffect(() => changeTitle(t("Announcements")), [i18n.resolvedLanguage]);

    const { data, fetchNextPage, hasNextPage, isFetching } = useSearchRelease({ take: TAKE }, {
        getNextPageParam: (lastPage, allPages) => lastPage.releases.length === TAKE ? allPages.length + 1 : undefined
    });

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
                if (hasNextPage && !isFetching) {
                    fetchNextPage();
                }
            }
        };

        const checkContentHeight = () => {
            if (document.body.offsetHeight <= window.innerHeight) {
                if (hasNextPage && !isFetching) {
                    fetchNextPage();
                }
            }
        };

        checkContentHeight();
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasNextPage, isFetching]);

    return (
        <Container maxWidth="xl" sx={{ my: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ float: "right" }}>
                        <TypographyWithIcon endIcon={<ArrowUpward fontSize="small" sx={{ opacity: 0.6 }} />} color="text.secondary">{t("Newest")}</TypographyWithIcon>
                    </Box>
                </Grid>

                {data?.pages.map(page =>
                    <Fragment key={newGuid()}>
                        {page.releases.map(release =>
                            <Grid item xs={12} key={release.id}>
                                <ReleaseCard release={release} />
                            </Grid>
                        )}
                    </Fragment>
                )}
            </Grid>
        </Container>
    );
};