import { GridRowId } from "@mui/x-data-grid-pro";
import { useInfiniteQuery, UseInfiniteQueryOptions, useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { useContext } from "react";
import { fetchDelete, fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import Release from "../models/Release";
import { apiUrl } from "../utils/BaseUrl";
import { ReleaseSearchRequest, ReleaseSearchResponse } from "../models/SearchModel";

export const queryKey: string = "Releases";

export function useGetReleases<TQueryFnData = Release[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}`, signal), options);
}

export function useGetRelease<TQueryFnData = Release, TError = string, TData = TQueryFnData>(id: GridRowId | undefined, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey, id], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}/${id}`, signal), options);
}

interface UseCreateOptions {
    onSuccess?: (data: Release) => void,
    onError?: (message: string) => void,
}

export function useCreateRelease(platformId: string, options?: UseCreateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Release) => fetchPost(`${apiUrl}/${queryKey}/platform/${platformId}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousDataList = queryClient.getQueryData<Release[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], [model, ...previousDataList]);

            return { previousDataList };
        },
        onError: (err: string, model, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: (model, variables, context) => {
            queryClient.invalidateQueries(["Apps"], { exact: false });
            if (context) {
                queryClient.setQueryData([queryKey], [model, ...context.previousDataList ?? []]);
            }
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
}

interface UseUpdateOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateRelease(options?: UseUpdateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Release) => fetchPut(`${apiUrl}/${queryKey}/${model.id}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });
            await queryClient.cancelQueries({ queryKey: [queryKey, model.id] });

            const previousDataList = queryClient.getQueryData<Release[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], previousDataList.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData<Release>([queryKey, model.id]);
            if (previousData)
                queryClient.setQueryData([queryKey, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (err: string, variables, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
                queryClient.setQueryData([queryKey, variables.id], context.previousData);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["Apps"], { exact: false });
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteRelease(options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((id: GridRowId) => fetchDelete(`${apiUrl}/${queryKey}/${id}`), {
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<Release[]>([queryKey]);

            if (previousData) {
                queryClient.setQueryData([queryKey], previousData.filter(prev => prev.id !== id));

                return { previousData };
            }
        },
        onError: (err: string, id, context) => {
            if (context)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["Apps"], { exact: false });
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

export function useSearchRelease<TQueryFnData = ReleaseSearchResponse, TError = string, TData = TQueryFnData>(data: ReleaseSearchRequest, options?: Omit<UseInfiniteQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useInfiniteQuery([queryKey, "search"], ({ pageParam }) => fetchPost(`${apiUrl}/${queryKey}/search?page=${pageParam ?? 1}`, data), options);
}