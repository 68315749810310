import { Button } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { Dialog, LayoutContext, newGuid } from "wcz-layout";
import App from "../../models/App";
import { AppType } from "../../models/enums/AppType";
import { PlatformType } from "../../models/enums/PlatformType";
import { useCreateApp } from "../../queries/AppQueries";
import { AppForm } from "./form/AppForm";
import { useNavigate } from "react-router-dom";

interface CreateAppDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const CreateAppDialog: React.FC<CreateAppDialogProps> = ({ open, setOpen }) => {
    const { t } = useContext(LayoutContext);
    const [app, setApp] = useState<App>({} as App);
    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            initApp();
        }
    }, [open]);

    const initApp = useCallback(() => {
        setApp({
            id: newGuid(),
            title: "",
            description: "",
            type: AppType.Web,
            categories: [],
            platforms: [{ id: newGuid(), type: PlatformType.Web, releases: [] }],
        });
    }, []);

    const { mutate } = useCreateApp({
        onSuccess: () => {
            navigate(`/apps/${app.id}`);
            onClose();
        }
    });

    const handleOnSubmit = useCallback(() => {
        mutate(app);
    }, [app]);

    const onClose = useCallback(() => {
        setOpen(false);
        setApp({} as App);
    }, []);

    return (
        <Dialog open={open} onClose={onClose} title={t("NewApplication")} color="primary" disableAutoClose actions={[
            <Button key="submit" variant="contained" onClick={handleOnSubmit}>{t("Submit")}</Button>
        ]}>
            <AppForm app={app} setApp={setApp} />
        </Dialog>
    );
};