import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchGet, fetchPost } from "wcz-layout";
import EmployeeSearchHistory from "../models/EmployeeSearchHistory";
import { apiUrl } from "../utils/BaseUrl";

const queryKey: string = "EmployeeSearchHistories";

export function useGetEmployeeSearchHistories<TQueryFnData = EmployeeSearchHistory[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}`, signal), options);
}

interface UseCreateOptions {
    onSuccess?: (data: EmployeeSearchHistory) => void,
    onError?: (message: string) => void,
}

export function useCreateEmployeeSearchHistory(options?: UseCreateOptions) {
    const queryClient = useQueryClient();

    return useMutation((model: EmployeeSearchHistory) => fetchPost(`${apiUrl}/${queryKey}`, model), {
        onError: (err: string) => {
            if (options?.onError)
                options.onError(err);
        },
        onSuccess: (model) => {
            queryClient.invalidateQueries([queryKey]);
            options?.onSuccess && options.onSuccess(model);
        },
    });
}