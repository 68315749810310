import { Autocomplete, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, TextField, Typography } from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import { FileUploadDropzone, FileViewer, useGetFileMetas } from "wcz-file";
import { LayoutContext, newGuid } from "wcz-layout";
import App from "../../../models/App";
import Category from "../../../models/Category";
import { AppType } from "../../../models/enums/AppType";
import { PlatformType } from "../../../models/enums/PlatformType";
import { useGetCategories } from "../../../queries/CategoryQueries";

interface AppFormProps {
    app: App;
    setApp: (app: App) => void;
}

export const AppForm: React.FC<AppFormProps> = ({ app, setApp }) => {
    const { t } = useContext(LayoutContext);
    const [isLogoUploaded, setIsLogoUploaded] = useState<boolean>(false);

    useGetFileMetas(app.id, {
        onSuccess: data => {
            setIsLogoUploaded(!!data.length);
        }
    });

    const { data: categories = [] } = useGetCategories({
        enabled: !!app.id
    });

    const handleOnTitleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setApp({ ...app, title: event.target.value }), [app]);
    const handleOnDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setApp({ ...app, description: event.target.value }), [app]);
    const handleOnCategoriesChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: Category[]) => setApp({ ...app, categories: value }), [app]);
    const handleOnTypeChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: AppType) => setApp({ ...app, type: value, platforms: [] }), [app]);

    const handleOnPlatformChange = useCallback((value: PlatformType) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const exists = app.platforms?.find(p => p.type === value);
        if (exists)
            setApp({ ...app, platforms: app.platforms?.filter(p => p.type !== value) });
        else
            setApp({ ...app, platforms: [...app.platforms, { id: newGuid(), type: value, releases: [] }] });
    }, [app]);

    const handleOnLogoUpload = useCallback(() => setIsLogoUploaded(true), []);
    const handleOnLogoDelete = useCallback(() => setIsLogoUploaded(false), []);

    const platformTypes = useMemo(() => app.type === AppType.Web ? [PlatformType.Web, PlatformType.PowerApps] : [PlatformType.Android, PlatformType.Iphone], [app]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField label={t("Title")} value={app.title ?? ""} onChange={handleOnTitleChange} fullWidth required variant="standard" />
            </Grid>

            <Grid item xs={12}>
                <TextField label={t("Description")} value={app.description ?? ""} onChange={handleOnDescriptionChange} fullWidth variant="standard" multiline />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    value={app.categories}
                    options={categories}
                    multiple
                    getOptionLabel={(option) => option.name}
                    onChange={handleOnCategoriesChange}
                    renderInput={(params) => <TextField {...params} label={t("Categories")} fullWidth variant="standard" />}
                />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    value={app.type ?? null}
                    options={Object.values(AppType)}
                    getOptionLabel={(option) => t(option)}
                    disableClearable
                    onChange={handleOnTypeChange}
                    renderInput={(params) => <TextField {...params} label={t("Type")} fullWidth required variant="standard" />}
                />
            </Grid>

            <Grid item xs={12}>
                <FormLabel component="legend" required>{t("Platforms")}</FormLabel>
                <FormGroup>
                    {platformTypes.map(platformType =>
                        <FormControlLabel key={platformType} label={platformType} control={
                            <Checkbox checked={app.platforms?.map(p => p.type).includes(platformType) ?? false} onChange={handleOnPlatformChange(platformType)} />} />
                    )}
                </FormGroup>
            </Grid>

            <Grid item xs={12}>
                <Typography gutterBottom>{t("Logo")}</Typography>
                {isLogoUploaded ?
                    <FileViewer subId={app.id} onDelete={handleOnLogoDelete} />
                    :
                    <FileUploadDropzone subId={app.id} maxFiles={1} onSuccess={handleOnLogoUpload} />
                }
            </Grid>
        </Grid>
    );
};