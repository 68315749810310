import { Button } from "@mui/material";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Dialog, LayoutContext, newGuid } from "wcz-layout";
import App from "../../models/App";
import Platform from "../../models/Platform";
import Release from "../../models/Release";
import { AppType } from "../../models/enums/AppType";
import { useCreateRelease } from "../../queries/ReleaseQueries";
import { ReleaseForm } from "./form/ReleaseForm";
import { ReleaseEnvironment } from "../../models/enums/ReleaseEnvironment";

interface CreateReleaseDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    platform: Platform;
    app: App;
}

export const CreateReleaseDialog: React.FC<CreateReleaseDialogProps> = ({ open, setOpen, platform, app }) => {
    const { t } = useContext(LayoutContext);
    const [release, setRelease] = useState<Release>({} as Release);

    useEffect(() => {
        if (open) {
            initRelease();
        }
    }, [open]);

    const initRelease = useCallback(() => {
        setRelease({
            ...release,
            id: newGuid(),
            version: platform?.latestProductionRelease?.version ?? "",
            environment: ReleaseEnvironment.Production,
            releaseDate: moment().format(),
            releaseNotes: "",
            value: app.type === AppType.Web ? platform?.latestProductionRelease?.value ?? "" : newGuid(),
        });
    }, [release, platform, app]);

    const { mutate } = useCreateRelease(platform.id, {
        onSuccess: () => onClose()
    });

    const handleOnSubmit = useCallback(() => {
        mutate(release);
    }, [release]);

    const onClose = useCallback(() => {
        setOpen(false);
        setRelease({} as Release);
    }, []);

    return (
        <Dialog open={open} onClose={onClose} title={t("NewReleaseFor", { type: platform.type })} color="primary" disableAutoClose actions={[
            <Button key="submit" variant="contained" onClick={handleOnSubmit}>{t("Submit")}</Button>
        ]}>
            <ReleaseForm release={release} setRelease={setRelease} platform={platform} app={app} />
        </Dialog>
    );
};