import { Campaign, Home, Tune } from "@mui/icons-material";
import { amber, indigo } from "@mui/material/colors";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FileProvider } from "wcz-file";
import { LayoutProvider, LeftDrawerItem, hasRole, useUser } from "wcz-layout";
import packageJson from "../package.json";
import { AppPage } from "./pages/AppPage";
import { CategoriesPage } from "./pages/CategoriesPage";
import { HomePage } from "./pages/HomePage";
import { ReleasesPage } from "./pages/ReleasesPage";
import AuthPolicy from "./utils/AuthPolicy";
import { fileUrl } from "./utils/BaseUrl";

export const App: React.FC = () => {
    const user = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
        { title: "Announcements", path: "/announcements", icon: <Campaign fontSize="large" /> },
        { title: "Categories", path: "/categories", icon: <Tune fontSize="large" />, hidden: !hasRole(AuthPolicy.Admin) },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={amber[500]} secondaryColor={indigo[500]} leftDrawerItems={leftDrawerItems}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <FileProvider baseUrl={fileUrl} appName={packageJson.name}>
                        <Routes>
                            <Route path='/' element={<HomePage />} />
                            <Route path='/app/:id' element={<AppPage />} />
                            <Route path='/apps/:id' element={<AppPage />} />
                            <Route path='/categories' element={<CategoriesPage />} />
                            <Route path='/announcements' element={<ReleasesPage />} />
                        </Routes>
                    </FileProvider>
                </LocalizationProvider>
            </LayoutProvider>
        </BrowserRouter>
    );
};