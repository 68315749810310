import { Avatar, Card, CardActionArea, CardContent, CardHeader, Typography } from "@mui/material";
import moment from "moment";
import "moment/locale/cs";
import "moment/locale/en-gb";
import { Fragment, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useGetFileMetas, useGetFileThumbnail } from "wcz-file";
import { LayoutContext } from "wcz-layout";
import Release from "../../models/Release";

interface ReleaseCardProps {
    release: Release;
}

export const ReleaseCard: React.FC<ReleaseCardProps> = ({ release }) => {
    const { t, i18n } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data: metas = [] } = useGetFileMetas(release.platform?.app?.id, {
        enabled: !!release.platform?.app?.id
    });

    const { data: src } = useGetFileThumbnail(metas[0], {
        enabled: !!metas.length
    });

    const handleClick = useCallback(() => navigate(`/apps/${release.platform?.app?.id}`), [release, navigate]);

    const getCreatedDate = useCallback((date: string) => {
        const localLocale = moment(date);
        localLocale.locale(i18n.language);
        return localLocale.fromNow();
    }, [i18n.resolvedLanguage]);

    if (!release.platform)
        return null;

    return (
        <Card>
            <CardActionArea onClick={handleClick}>
                <Typography sx={{ fontSize: 13, px: 2, pt: 1, opacity: 0.5 }}>{t("ForPlatform")} {t(release.platform.type)} {getCreatedDate(release.releaseDate)} </Typography>

                <CardHeader
                    avatar={<Avatar src={src} sx={{ width: 60, height: 60, borderRadius: 3 }} />}
                    title={<Typography>{release.platform.app?.title}</Typography>}
                    subheader={
                        <Fragment>
                            <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>{t("Version")}: </b>{release.version}</Typography>
                            <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>{t("Environment")}: </b>{t(release.environment)}</Typography>

                        </Fragment>
                    }
                />

                <CardContent>
                    {release.releaseNotes.split("\n").map((paragraph, index) => <Typography color="text.primary" key={index}>{paragraph}</Typography>)}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};