import packageJson from "../../package.json";
import Release from "../models/Release";
import { ReleaseEnvironment } from "../models/enums/ReleaseEnvironment";
import { fileUrlNA } from "./BaseUrl";

export const isAndroid: boolean = /(android)/i.test(navigator.userAgent);
export const isIphone: boolean = /(android|iphone)/i.test(navigator.userAgent);

export const getPlistContent = (fileId: string, release: Release, title: string) =>
  `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
<plist version="1.0">
<dict>
    <key>items</key>
    <array>
      <dict>
        <key>assets</key>
        <array>
          <dict>
            <key>kind</key>
            <string>software-package</string>
            <key>url</key>
            <string>${fileUrlNA}/v1/${packageJson.name}/${fileId}</string>
          </dict>
        </array>
        <key>metadata</key>
        <dict>
          <key>bundle-identifier</key>
          <span>${`com.wistron.wczdeviceinfo${release.environment === ReleaseEnvironment.Development ? "-qas" : ""}`}</span>
          <key>bundle-version</key>
          <string>${release.version}</string>
          <key>kind</key>
          <string>software</string>
          <key>subtitle</key>
          <string>${title}</string>
          <key>title</key>
          <string>${title}</string>
        </dict>
      </dict>
    </array>
  </dict>
</plist>`;