import { Button } from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import { Dialog, LayoutContext } from "wcz-layout";
import Release from "../../models/Release";
import { useGetRelease, useUpdateRelease } from "../../queries/ReleaseQueries";
import { ReleaseForm } from "./form/ReleaseForm";
import Platform from "../../models/Platform";
import App from "../../models/App";

interface EditReleaseDialogProps {
    id: string;
    setId: (id: string) => void;
    platform: Platform;
    app: App;
}

export const EditReleaseDialog: React.FC<EditReleaseDialogProps> = ({ id, setId, platform, app }) => {
    const { t } = useContext(LayoutContext);
    const [release, setRelease] = useState<Release>({} as Release);
    const open = useMemo(() => !!id, [id]);

    useGetRelease(id, {
        enabled: open,
        refetchOnWindowFocus: false,
        onSuccess: data => setRelease(data)
    });

    const { mutate } = useUpdateRelease({
        onSuccess: () => onClose()
    });

    const handleOnSubmit = useCallback(() => {
        mutate(release);
    }, [release]);

    const onClose = useCallback(() => setId(""), []);

    return (
        <Dialog open={open} onClose={onClose} title={t("EditReleaseFor", { type: platform.type })} color="primary" disableAutoClose actions={[
            <Button key="submit" variant="contained" onClick={handleOnSubmit}>{t("Submit")}</Button>
        ]}>
            <ReleaseForm release={release} setRelease={setRelease} platform={platform} app={app} />
        </Dialog>
    );
};