import { isDevelopment } from "wcz-layout";

let apiUrl: string = "https://api.dev.wistron.eu/app-store";
let fileUrl = "https://api.dev.wistron.eu/file";
let fileUrlNA = "https://api.dev.wistron.eu/na/file";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/app-store";
    fileUrl = "https://api.wistron.eu/file";
    fileUrlNA = "https://api.wistron.eu/na/file";
}

export { apiUrl, fileUrl, fileUrlNA };