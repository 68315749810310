import { Edit } from "@mui/icons-material";
import { Divider, IconButton, List, ListItem, ListItemText, Theme, Tooltip, Typography, darken, lighten } from "@mui/material";
import moment from "moment";
import { Fragment, useCallback, useContext } from "react";
import { Dialog, LayoutContext, hasRole } from "wcz-layout";
import Platform from "../../models/Platform";
import Release from "../../models/Release";
import AuthPolicy from "../../utils/AuthPolicy";
import { grey } from "@mui/material/colors";

export const getBackgroundColor = (color: string, theme: Theme) => theme.palette.mode === "dark" ? darken(color, 0.8) : lighten(color, 0.8);

interface ReleasesDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    platform: Platform;
    handleOnEditClick: (release: Release) => () => void;
}

export const ReleasesDialog: React.FC<ReleasesDialogProps> = ({ open, setOpen, platform, handleOnEditClick }) => {
    const { t } = useContext(LayoutContext);

    const onClose = useCallback(() => setOpen(false), [setOpen]);

    return (
        <Dialog open={open} onClose={onClose} title={t("Releases")} color="primary" disablePadding maxWidth="md">
            <List>
                {platform.releases?.map(release =>
                    <Fragment key={release.id}>
                        <ListItem sx={theme => ({ "&:hover": { bgcolor: getBackgroundColor(grey[300], theme) } })}>
                            <ListItemText
                                primary={
                                    <Fragment>
                                        <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>{t("Version")}: </b>{release.version}</Typography>
                                        <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>{t("Environment")}: </b>{t(release.environment)}</Typography>

                                        <Typography color="text.secondary" sx={{ fontSize: 13 }} gutterBottom>
                                            <Tooltip title={`${release.createdBy} ${t("In")} ${moment(release.releaseDate).formatDateTime()}`}>
                                                <span>
                                                    <b>{t("DateOfUpdate")}: </b>{moment(release.releaseDate).formatDate()}
                                                </span>
                                            </Tooltip>
                                        </Typography>

                                    </Fragment>
                                }
                                secondary={
                                    <Fragment>
                                        {release.releaseNotes.split("\n").map((paragraph, index) => <Typography color="text.primary" key={index}>{paragraph}</Typography>)}

                                    </Fragment>
                                }
                            />

                            {hasRole(AuthPolicy.Contributor) &&
                                <IconButton onClick={handleOnEditClick(release)}>
                                    <Edit />
                                </IconButton>
                            }
                        </ListItem>

                        <Divider sx={{ mx: 2 }} />
                    </Fragment>
                )}
            </List>
        </Dialog>
    );
};