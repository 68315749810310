import { GridRowId } from "@mui/x-data-grid-pro";
import { useInfiniteQuery, UseInfiniteQueryOptions, useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { useContext } from "react";
import { fetchDelete, fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import App from "../models/App";
import { apiUrl } from "../utils/BaseUrl";
import { AppSearchRequest, AppSearchResponse } from "../models/SearchModel";

export const queryKey: string = "Apps";

export function useGetApps<TQueryFnData = App[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}`, signal), options);
}

export function useGetApp<TQueryFnData = App, TError = string, TData = TQueryFnData>(id: GridRowId | undefined, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey, id], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}/${id}`, signal), options);
}

interface UseCreateOptions {
    onSuccess?: (data: App) => void,
    onError?: (message: string) => void,
}

export function useCreateApp(options?: UseCreateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: App) => fetchPost(`${apiUrl}/${queryKey}`, model), {
        onError: (err: string) => {
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: (model) => {
            queryClient.invalidateQueries([queryKey], { exact: false });
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
}

interface UseUpdateOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateApp(options?: UseUpdateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: App) => fetchPut(`${apiUrl}/${queryKey}/${model.id}`, model), {
        onError: (err: string) => {
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey], { exact: false });
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteApp(options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((id: GridRowId) => fetchDelete(`${apiUrl}/${queryKey}/${id}`), {
        onError: (err: string) => {
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey], { exact: false });
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

export function useSearchApp<TQueryFnData = AppSearchResponse, TError = string, TData = TQueryFnData>(data: AppSearchRequest, options?: Omit<UseInfiniteQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useInfiniteQuery([queryKey, "search"], ({ pageParam }) => fetchPost(`${apiUrl}/${queryKey}/search?page=${pageParam ?? 1}`, data), options);
}