import { Box, Divider, List, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { LayoutContext, newGuid } from "wcz-layout";
import { PlatformType } from "../../models/enums/PlatformType";
import { queryKey, useSearchApp } from "../../queries/AppQueries";
import { useCreateEmployeeSearchHistory } from "../../queries/EmployeeSearchHistoryQueries";
import { AppListItem } from "../app/AppListItem";
import { SearchFilters } from "./advancedSearch/SearchFilters";
import { SearchInput } from "./advancedSearch/SearchInput";

const TAKE = 10;

export const AdvancedSearch: React.FC = () => {
    const { t } = useContext(LayoutContext);
    const queryClient = useQueryClient();
    const [inputValue, setInputValue] = useState<string>("");
    const inputValueTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const selectedCategoryTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [selectedPlatform, setSelectedPlatform] = useState<PlatformType | undefined>();
    const selectedPlatformTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const { data, refetch, isFetching, fetchNextPage, hasNextPage } = useSearchApp({ value: inputValue, category: selectedCategory, platform: selectedPlatform, take: TAKE }, {
        getNextPageParam: (lastPage, allPages) => lastPage.apps.length === TAKE ? allPages.length + 1 : undefined
    });

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
                if (hasNextPage && !isFetching) {
                    fetchNextPage();
                }
            }
        };

        const checkContentHeight = () => {
            if (document.body.offsetHeight <= window.innerHeight) {
                if (hasNextPage && !isFetching) {
                    fetchNextPage();
                }
            }
        };

        checkContentHeight();
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasNextPage, isFetching]);

    const setInputValueWithDebounce = useCallback((value: string) => {
        setInputValue(value);

        if (inputValueTimeoutRef.current)
            clearTimeout(inputValueTimeoutRef.current);

        inputValueTimeoutRef.current = setTimeout(() => search(), 500);
    }, []);

    const setSelectedCategoryDebounce = useCallback((value: string) => {
        setSelectedCategory(value);

        if (selectedCategoryTimeoutRef.current)
            clearTimeout(selectedCategoryTimeoutRef.current);

        selectedCategoryTimeoutRef.current = setTimeout(() => search(), 500);
    }, []);

    const setSelectedPlatformDebounce = useCallback((value: PlatformType | undefined) => {
        setSelectedPlatform(value);

        if (selectedPlatformTimeoutRef.current)
            clearTimeout(selectedPlatformTimeoutRef.current);

        selectedPlatformTimeoutRef.current = setTimeout(() => search(), 500);
    }, []);

    const search = useCallback(() => {
        queryClient.cancelQueries([queryKey, "search"]);
        refetch();
    }, []);

    const dataLength = useMemo(() => data?.pages.reduce((acc, page) => acc + page.apps.length, 0), [data]);

    const { mutate: createEmployeeSearchHistory } = useCreateEmployeeSearchHistory();

    useEffect(() => {
        if (inputValue && dataLength) {
            const timeout = setTimeout(() => {
                createEmployeeSearchHistory({ searchText: inputValue });
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [inputValue, dataLength]);

    return (
        <Box sx={{ mb: 9 }}>
            <SearchInput searchText={inputValue} setSearchText={setInputValueWithDebounce} />

            <SearchFilters selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategoryDebounce} selectedPlatform={selectedPlatform} setSelectedPlatform={setSelectedPlatformDebounce} />

            <List>
                {data?.pages.map(page =>
                    <Fragment key={newGuid()}>
                        {page.apps.map(app =>
                            <Fragment key={app.id}>
                                <AppListItem app={app} />
                                <Divider />
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </List>

            {(!isFetching && !dataLength) && <Typography>{t("NoApplicationsFound")}</Typography>}
        </Box>
    );
};