import { History, Search } from "@mui/icons-material";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LayoutContext, TypographyWithIcon, isMobile } from "wcz-layout";
import { useGetEmployeeSearchHistories } from "../../../queries/EmployeeSearchHistoryQueries";

interface SearchInputProps {
    searchText: string;
    setSearchText: (searchText: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ searchText, setSearchText }) => {
    const [options, setOptions] = useState<string[]>([]);
    const { t } = useContext(LayoutContext);
    const showHistory = useMemo(() => !searchText, [searchText]);

    const { data: employeeSearchHistories = [] } = useGetEmployeeSearchHistories();

    useEffect(() => {
        if (showHistory && employeeSearchHistories.length)
            setOptions(employeeSearchHistories.map(esh => esh.searchText));
        else
            setOptions([]);
    }, [showHistory, employeeSearchHistories]);

    const handleOnInputChange = useCallback((value: string, reason?: string) => {
        if (reason === "clear")
            return setSearchText("");

        setSearchText(value);
    }, []);

    return (
        <Autocomplete
            value={searchText}
            options={options}
            autoHighlight
            autoComplete
            clearOnEscape
            freeSolo
            blurOnSelect
            onInputChange={(e, value, reason) => handleOnInputChange(value, reason)}
            renderInput={(params) =>
                <TextField {...params} placeholder={t("Search")} autoFocus={!isMobile}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            }
            renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    <TypographyWithIcon startIcon={<History />}>{option}</TypographyWithIcon>
                </Box>
            )}
        />
    );
};