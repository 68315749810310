import { ArrowDropDown, Done, Search } from "@mui/icons-material";
import { Box, Chip, Grid, InputAdornment, List, ListItemButton, ListItemText, Menu, TextField } from "@mui/material";
import React, { Fragment, useCallback, useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";
import { useGetCategories } from "../../../queries/CategoryQueries";
import { PlatformType } from "../../../models/enums/PlatformType";

interface SearchInputProps {
    selectedCategory: string;
    setSelectedCategory: (value: string) => void;
    selectedPlatform: PlatformType | undefined;
    setSelectedPlatform: (value: PlatformType | undefined) => void;
}

export const SearchFilters: React.FC<SearchInputProps> = ({ selectedCategory, setSelectedCategory, selectedPlatform, setSelectedPlatform }) => {
    const { t } = useContext(LayoutContext);
    const [categoriesMenu, setCategoriesMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [searchCategory, setSearchCategory] = useState<string>("");
    const [platformMenu, setPlatformMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);

    const { data: categories = [] } = useGetCategories();

    const openCategoriesMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => setCategoriesMenu({ mouseX: event.clientX, mouseY: event.clientY }), []);

    const handleOnCategorySearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchCategory(e.target.value), []);

    const handleOnCategoryClick = useCallback((newValue: string) => () => {
        setSelectedCategory(newValue);
        closeCategoriesMenu();
    }, []);

    const handleClearCategoryFilter = useCallback(() => {
        setSelectedCategory("");
        closeCategoriesMenu();
    }, []);

    const closeCategoriesMenu = useCallback(() => {
        setCategoriesMenu(null);
        setTimeout(() => setSearchCategory(""), 200);
    }, []);

    const openPlatformMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => setPlatformMenu({ mouseX: event.clientX, mouseY: event.clientY }), []);

    const handleOnPlatformClick = useCallback((newValue: PlatformType) => () => {
        setSelectedPlatform(newValue);
        setPlatformMenu(null);
    }, []);

    const handleClearPlatformFilter = useCallback(() => {
        setSelectedPlatform(undefined);
        closePlatformMenu();
    }, []);

    const closePlatformMenu = useCallback(() => setPlatformMenu(null), []);

    return (
        <Fragment>
            <Grid container spacing={1} justifyContent="center" mt={0} mb={1}>
                <Grid item>
                    <Chip
                        label={selectedPlatform || t("Platforms")}
                        variant={selectedPlatform ? "filled" : "outlined"}
                        onClick={openPlatformMenu}
                        onDelete={openPlatformMenu}
                        deleteIcon={<ArrowDropDown />}
                        icon={selectedPlatform ? <Done /> : undefined}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        label={selectedCategory || t("Categories")}
                        variant={selectedCategory ? "filled" : "outlined"}
                        onClick={openCategoriesMenu}
                        onDelete={openCategoriesMenu}
                        deleteIcon={<ArrowDropDown />}
                        icon={selectedCategory ? <Done /> : undefined}
                    />
                </Grid>
            </Grid>

            <Menu open={categoriesMenu !== null} onClose={closeCategoriesMenu} anchorReference="anchorPosition" variant="menu"
                anchorPosition={categoriesMenu !== null ? { top: categoriesMenu.mouseY, left: categoriesMenu.mouseX } : undefined}>
                <Box sx={{ width: 320, maxHeight: 380, p: 1 }}>
                    <TextField placeholder={t("Search")} fullWidth variant="standard" value={searchCategory} onChange={handleOnCategorySearchChange}
                        InputProps={{ startAdornment: <InputAdornment position="start"><Search /></InputAdornment> }} />

                    <List dense>
                        {!searchCategory &&
                            <ListItemButton onClick={handleClearCategoryFilter}>
                                <ListItemText primary={t("AllCategories")} />
                            </ListItemButton>
                        }

                        {categories.filter(c => c.name.toLowerCase().includes(searchCategory.toLowerCase())).map(c =>
                            <ListItemButton key={c.id} onClick={handleOnCategoryClick(c.name)} selected={c.name === selectedCategory}>
                                <ListItemText primary={c.name} />
                            </ListItemButton>
                        )}
                    </List>
                </Box>
            </Menu>

            <Menu open={platformMenu !== null} onClose={closePlatformMenu} anchorReference="anchorPosition" variant="menu"
                anchorPosition={platformMenu !== null ? { top: platformMenu.mouseY, left: platformMenu.mouseX } : undefined}>
                <Box sx={{ width: 320, maxHeight: 380, p: 1 }}>
                    <List dense>
                        <ListItemButton onClick={handleClearPlatformFilter}>
                            <ListItemText primary={t("AllPlatforms")} />
                        </ListItemButton>

                        {Object.values(PlatformType).map(p =>
                            <ListItemButton key={p} onClick={handleOnPlatformClick(p)} selected={p === selectedPlatform}>
                                <ListItemText primary={p} />
                            </ListItemButton>
                        )}
                    </List>
                </Box>
            </Menu>
        </Fragment>
    );
};