import { DataGridPremium, GridColDef, GridRowModel } from "@mui/x-data-grid-premium";
import React, { useContext, useEffect, useMemo } from "react";
import { LayoutContext } from "wcz-layout";
import { EditableHeader, GridDeleteCellItem, GridToolbar, TableContainer } from "wcz-x-data-grid";
import Category from "../models/Category";
import { useCreateCategory, useDeleteCategory, useGetCategories, useUpdateCategory } from "../queries/CategoryQueries";

export const CategoriesPage: React.FC = () => {
    const { changeTitle, t, i18n } = useContext(LayoutContext);

    useEffect(() => changeTitle(t("Categories")), [i18n.resolvedLanguage]);

    const { data = [], isFetching } = useGetCategories();
    const { mutateAsync: create } = useCreateCategory();
    const { mutateAsync: update } = useUpdateCategory();
    const remove = useDeleteCategory();

    const columns = useMemo(() => [
        {
            field: "id", type: "actions", width: 50,
            getActions: (params: any) => [
                <GridDeleteCellItem key="remove" id={params.id} remove={remove} showInMenu />
            ],
        },
        { field: "name", headerName: t("Name"), width: 400, editable: true, renderHeader: EditableHeader },
    ] as GridColDef[], [i18n.resolvedLanguage]);

    const processRowUpdate = async (row: GridRowModel<Category>): Promise<Category> => {
        if (row.isNew)
            await create(row);
        else {
            await update(row);
        }

        return { ...row, isNew: false };
    };

    return (
        <TableContainer>
            <DataGridPremium columns={columns} rows={data} slots={{ toolbar: GridToolbar }} loading={isFetching} editMode="row" processRowUpdate={processRowUpdate} />
        </TableContainer>
    );
};