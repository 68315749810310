import { Add } from "@mui/icons-material";
import { Button, Container, Grid } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext, hasRole } from "wcz-layout";
import { AdvancedSearch } from "../components/homepage/AdvancedSearch";
import { CreateAppDialog } from "../components/app/CreateAppDialog";
import AuthPolicy from "../utils/AuthPolicy";

export const HomePage: React.FC = () => {
    const { changeTitle, t } = useContext(LayoutContext);
    const [newAppOpen, setNewAppOpen] = useState<boolean>(false);

    useEffect(() => changeTitle("App Store"), []);

    const toggleNewDocument = useCallback(() => setNewAppOpen(!newAppOpen), [newAppOpen]);

    return (
        <Container maxWidth="xl" sx={{ my: 2 }}>
            <Grid container spacing={1}>
                {hasRole(AuthPolicy.Contributor) &&
                    <Grid item xs={12} sx={{ textAlign: "right", mb: 1 }}>
                        <Button variant="contained" startIcon={<Add />} onClick={toggleNewDocument}>{t("NewApplication")}</Button>
                    </Grid>
                }

                <Grid item xs={12}>
                    <AdvancedSearch />
                </Grid>
            </Grid>

            <CreateAppDialog open={newAppOpen} setOpen={setNewAppOpen} />
        </Container>
    );
};